/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: Login.css
# Original Author: PHAM DUY HOAN
# Date created: 17 JUL 2021
# Purpose: Login style css
# Revision History Raises (if any issues):
*/
.step{
    background: #1abc9c;
    padding: 20px 20px;
    text-align: center;
    font-size: 2rem
}
