/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: SecondStep.css
# Original Author: PHAM DUY HOAN
# Date created: 17 JUL 2021
# Purpose: Second Step style css
# Revision History Raises (if any issues):
*/
.form-group{
    align-content: center;
    padding:5px;
}

.upload-file{
    align-content: center;
    padding:5px;    
}

.container {
    display: flex;
    justify-content: center;
  }

.center {
width: 800px;
}