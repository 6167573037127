.page-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: fit-content;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.logo {
    flex-direction: center;
    display: block;
    zoom: 0.5;
    width: 200px;
}

.logo-mom {
    width: 200px;
    height: fit-content;
}

.IntroLink {
    display: block;
}